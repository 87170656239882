<template>
  <div class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
      <div class="content-wrapper d-flex align-items-center auth px-0">
        <div class="row w-100 mx-0">
          <div class="col-lg-4 mx-auto">
            <div class="auth-form-light text-left py-5 px-4 px-sm-5">
              <div class="brand-logo1" style="display: flex; justify-content: center">
                <img src="../../public/images/logo3.png" alt="logo" style="width: 30%" />
              </div>
              <h4>Forgot Password</h4>
              <form class="pt-3" @submit.prevent="forgotpassword">
                <div class="alert alert-warning alert-dismissible fade show" role="alert" v-if="msg != ''">
                  <strong>{{ msg }}</strong>
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="form-group">
                  <input type="email" name="email" class="form-control form-control-lg" id="exampleInputEmail1"
                    placeholder="Email" v-model="email" style="color: #242323" />
                </div>

                <div class="mt-3">
                  <button type="submit"
                    class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn text-white"
                    style="background-color: #1e1e2f; border: none">
                    FORGOT PASSWORD
                  </button>
                </div>

                <div class="text-center mt-4 font-weight-light">
                  <router-link to="/login" class="text-black font-weight-bold">Remembered Password</router-link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- content-wrapper ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
</template>

<script>
import baseUrl from "@/shared/baseUrl";
export default {
  data() {
    return {
      email: "",
      password: "",
      msg: "",
    };
  },
  methods: {
    forgotpassword() {
      $("#loader").show();
      const user = {
        email: this.email,
      };
      if (user.email == "") {
        this.msg = "Please enter email address";
        $("#loader").hide();
      } else {
        baseUrl
          .post("/forgotpassword", user)
          .then((response) => {
            $("#loader").hide();
            console.log(response.data);
            if (response.data.status == "03") {
              this.msg = response.data.message;
            } else {
              console.log(response.data.data);
              const vm = this;
              setTimeout(function () {
                vm.$router.push("/login");
              }, 5000);
            }
          })
          .catch((error) => {
            $("#loader").hide();
            this.msg = error.message;
          });
      }
    },
  },
};
</script>
<style scoped>
* {
  font-family: "Open Sans", sans-serif !important;
}

#loader {
  margin-left: 10px;
}
</style>
